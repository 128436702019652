<template>
    <div style="padding: 1.25rem;">
        <CRow>
            <CCol md="12" lg="4" class="d-flex align-items-center">
                <div>
                    <object :data="srcError" type="image/*" style="width:100%;height:70px;">
                        <img
                            :src="VesselRoute"
                            :alt="VesselName"
                            width="100%"
                            height="70px"
                            class="img-thumbnail mb-2"
                        />
                    </object>
                    
                </div>
            </CCol>
            <CCol md="12" lg="8">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="2">
                        <img class=" rounded" width="100%" height="100%" src="/img/IconsVesselInfo/buque.svg" alt="Card image cap">
                    </CCol>
                    <CCol class="Img-size d-flex align-items-center" lg="10">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:11px;">{{ VesselName }}, {{ VesselTpVesselName }}</label>
                    </CCol>
                </CRow>
                <CRow style="margin-bottom:10px">
                    <CCol class="d-flex align-items-center" lg="2">
                        <div class="Img-size">
                            <img class="rounded" width="100%" height="100%" src="/img/IconsVesselInfo/pais.svg" alt="Card image cap">
                        </div>
                    </CCol>
                    <CCol lg="4">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:11px;">{{ VesselCountry }}</label>
                        <div class="Img-size d-flex align-items-center">
                            <img width="100%" height="100%" :src="VesselCountryRoute" alt="Card image cap" />
                        </div>
                    </CCol>
                    <CCol class="d-flex align-items-center" lg="2">
                        <div class="Img-size">
                            <img class="rounded" width="100%" height="100%" src="/img/IconsVesselInfo/imo.png" alt="Card image cap">
                        </div>
                    </CCol>
                    <CCol class="px-0 d-flex align-items-center" lg="4">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:11px;">{{ VesselImo }}</label>
                    </CCol>
                </CRow>
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="2">
                        <img width="100%" height="100%" src="/img/IconsVesselInfo/call.svg" alt="Card image cap">
                    </CCol>
                    <CCol class="Img-size" lg="4">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:11px;">CALL SIGN</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ VesselCallSign }}</label>
                    </CCol>
                    <CCol class="Img-size" lg="2">
                        <img width="100%" height="100%" src="/img/IconsVesselInfo/mmsi.svg" alt="Card image cap">
                    </CCol>
                    <CCol style="padding:0px" lg="4">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:11px;">MMSI</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ VesselMmsi }}</label>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <hr />
        <CRow style="margin-bottom:10px">
            <CCol style="padding:0x;" lg="4">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/loa.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">LOA</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselLoa, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
            <CCol style="padding:0x;" lg="4">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/lwl.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">LWL</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselLwl, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
            <CCol style="padding:0x;" lg="4">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/gross.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">GROSS</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselGross, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CRow style="margin-bottom:10px">
            <CCol style="padding:0x;" lg="4">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/proa.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">PROA</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselProa, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
            <CCol style="padding:0x;" lg="4">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/popa.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">POPA</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselPopa, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
            <CCol style="padding:0x;" lg="4" v-if="!FgBulk">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/teus.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">TEUS</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselTeus, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
            <CCol style="padding:0x;" lg="4" v-else>
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/summerdwt.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">SUMMERDWT</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselSummerDwt, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CRow>
            <CCol style="padding:0x;" lg="4" v-if="!FgBulk">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/summerdwt.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">SUMMERDWT</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselSummerDwt, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
            <CCol style="padding:0x;" lg="4">
                <CRow style="margin-bottom:10px">
                    <CCol  lg="4">
                        <img class=" rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/grua_bordo.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">{{ $t('label.OnBoard') }}</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ VesselGruaBordo+' '+$t('label.crane')+'S' }}</label>
                    </CCol>
                </CRow>
            </CCol>
            <CCol style="padding:0x;" lg="4">
                <CRow style="margin-bottom:10px">
                    <CCol class="Img-size" lg="4">
                        <img class="rounded" width="100%" height="100%"  src="/img/IconsVesselInfo/fuel_oil_capacity.svg" alt="Card image cap">
                    </CCol>
                    <CCol lg="8">
                        <label style="font-weight: 600;margin-bottom:0px;font-size:12px;">{{ $t('label.Combustible') }}</label><br/>
                        <label style="font-size:10px;margin-bottom:0px;">{{ NumberFormat(VesselFuelOilCapacity, 2) }}</label>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { NumberFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            srcError:'/img/errorimage.jpg',            
        }
    }
    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }
    //Computed
    function VesselRoute(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return `${this.$store.getters['connection/getBase']}${Data.VesselRoute.replace('public/','', null, 'i')}`
        }
        return '/img/errorimage.jpg';
    }
    function VesselCountryRoute(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return `${this.$store.getters['connection/getBase']}${Data.CountryIcon.replace('public/','', null, 'i')}`
        }
        return '/img/errorimage.jpg';
    }   
    function VesselName(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.VesselName;
        }
        return "";
    }
    function VesselImo(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.Imo;
        }
        return "";
    }
    function VesselCountry(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.CountryName;
        }
        return "";
    }
    function VesselMmsi(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.Mmsi;
        }
        return "";
    }
    function VesselTpVesselName(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.TpVesselName;
        }
        return "";
    }
    function VesselCallSign(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.CallSign;
        }
        return "";
    }
    function VesselLoa(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return  Data.Loa + " MTR";
        }
        return "";
    }
    function VesselLwl(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.Lwl + " MTR";
        }
        return "";
    }
    function VesselGross(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.Gross + " TON";
        }
        return "";
    }
    function VesselProa(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.DraftBow + " MTR";
        }
        return "";
    }
    function VesselPopa(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.DraftStern + " MTR";
        }
        return "";
    }
    function VesselTeus(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.Teus;
        }
        return "";
    }
    function VesselSummerDwt(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.SummerDwt;
        }
        return "";
    }
    function VesselGruaBordo(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.VesselCrane;
        }
        return "";
    }
    function VesselFuelOilCapacity(){
        let Data = this.VesselInfoJson;
        if(Object.keys(Data).length !== 0){
            return Data.FuelOilCapacity + " M³";
        }
        return "";
    }
    export default {
        name:'InformationVessel',
        data,
        props: {
            VesselInfoJson:{
                type: Object,
                default: function () {
                    return {};
                },
            },
        },
        methods: {
            NumberFormat,
        },
        computed:{
            VesselName,
            VesselImo,
            VesselCountry,
            VesselMmsi,
            VesselTpVesselName,
            VesselCallSign,
            VesselLoa,
            VesselLwl,
            VesselGross,
            VesselProa,
            VesselPopa,
            VesselTeus,
            VesselSummerDwt,
            VesselGruaBordo,
            VesselFuelOilCapacity,
            VesselRoute,
            VesselCountryRoute,
            ...mapState({
                FgBulk: state => state.situacionOperativaActual.FgBulk,
            })
        }   
    }
</script>
<style scoped>
    .Img-size{
        width: 35px;
        height: 35px;
    }
</style>